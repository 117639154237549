<template>
  <div>
    <k-progress-circular
      :value="progress"
      labeProgress="Analisando documentos cadastrados"
    >
      <h2 class="custom-title-secondary mb-1"
        >Ótimo! Entraremos em contato no dia e horário agendados!</h2
      >
      <p class="custom-normal-text">
        Acompanhe a evolução do seu processo pela indicação abaixo e aproveite
        para acessar os conteúdos que separamos para você!
      </p>
    </k-progress-circular>
    <blog />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { authMethods } from '@state/helpers'

const kProgressCircular = () =>
  import('@src/components/widget/ProgressCircular.vue')
import longPulling from '@src/services/long-pulling'
import blog from '@components/registering/Blog.vue'

export default {
  name: 'analize',
  components: {
    kProgressCircular,
    blog,
  },
  data() {
    return {
      dispatchProgress: 'backOffice/getBackOfficeProgress',
      dispatchUserUpdate: 'auth/fetchUser',
    }
  },
  computed: {
    ...mapGetters({
      progress: 'backOffice/progress',
      company: 'company/company',
    }),
  },
  mounted() {
    longPulling.start(this.dispatchUserUpdate)
    longPulling.start(this.dispatchProgress, 'analize')
    this.sendToGA()
  },
  beforeDestroy() {
    longPulling.stop(this.dispatchProgress, 'analize')
  },

  methods: {
    ...authMethods,
    sendToGA() {
      if (this.checkStepRegistration(6)) {
        this.$ga.event({
          eventCategory: this.company.isOppening ? 'Abertura' : 'Migração',
          eventAction: 'ativacao',
          eventLabel: 'analise',
          eventValue: 6,
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@design';
.no-box-shadow {
  box-shadow: none;
}
</style>
